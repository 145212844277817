import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, post } from '../../api/clients/RestClient';
import { addFetchedCases } from '../../api/common/fetched';
import { initialState } from './checkIn.state';
import { Org } from '../org/models/org';

export const getOrg = createAsyncThunk('checkIn/getOrg', async ({ slug }: { slug: string }) => {
  return await get<Pick<Org, 'id' | 'image' | 'name' | 'transparentImageBackground'>>('/checkins/' + slug);
});

export type CheckInInput = {
  phone: number;
  firstName?: string | null;
  lastName?: string | null;
  birthday?: string | null;
};

export const checkIn = createAsyncThunk('checkIn/checkIn', async ({ slug, input }: { slug: string; input: CheckInInput }) => {
  await post<{
    phone: number;
    isRegistered: boolean;
  }>('/checkins/' + slug, input);
});

export const checkInSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchedCases(builder, getOrg, (state, fetched) => (state.getOrgOperation = fetched));
    addFetchedCases(builder, checkIn, (state, fetched) => (state.checkInOperation = fetched));
  },
});
