export const Notifications = {
  ANNOUNCEMENT_DELETE_SUCCESS: 'Announcement removed successfully.',
  ANNOUNCEMENT_DELETE_ERROR: 'An error occured, please try again.',
  ANNOUNCEMENT_SEND_SUCCESS: 'Announcement sent successfully.',
  ANNOUNCEMENT_SEND_ERROR: 'An error occured, please try again.',
  ANNOUNCEMENT_DRAFT_SUCCESS: 'Announscement draft saved successfully.',
  ANNOUNCEMENT_DRAFT_ERROR: 'An error occured, please try again.',
  ANNOUNCEMENT_CANCEL_SUCCESS: 'Announcement cancelled sucessfully.',
  ANNOUNCEMENT_CANCEL_ERROR: 'An error occured, please try again.',
  ANNOUNCEMENT_UPDATE_SUCCESS: 'Announcement updated successfully.',
  ANNOUNCEMENT_UPDATE_ERROR: 'An error occured, please try again.',
  PROMOTION_SEND_SUCCESS: 'Promotion sent successfully.',
  PROMOTION_DRAFT_SUCCESS: 'Promotion draft saved successfully.',
  PROMOTION_DELETE_SUCCESS: 'Promotion removed successfully.',
  PROMOTION_CANCEL_SUCCESS: 'Promotion cancelled sucessfully.',
  PROMOTION_UPDATE_SUCCESS: 'Promotion updated successfully.',
  PROMOTION_UPDATE_ERROR: 'An error occured, please try again.',
  PROMOTION_SEND_ERROR: 'An error occured, please try again.',
  PROMOTION_DRAFT_ERROR: 'An error occured, please try again.',
  PROMOTION_DELETE_ERROR: 'An error occured, please try again.',
  REWARD_DELETE_SUCCESS: 'Reward removed successfully.',
  REWARD_DELETE_ERROR: 'An error occured, please try again.',
  REWARD_CREATE_SUCCESS: 'Reward created successfully.',
  REWARD_CREATE_ERROR: 'An error occured, please try again.',
  REWARD_UPDATE_SUCCESS: 'Reward updated successfully.',
  REWARD_UPDATE_ERROR: 'An error occured, please try again.',
  DEVICE_UNREGISTER_SUCCESS: 'Device unregistered successfully.',
  DEVICE_UNREGISTER_ERROR: 'An error occurred during device unregistration.',
  DEVICE_UPDATE_SUCCESS: 'Device updated successfully',
  DEVICE_UPDATE_ERROR: 'An error occurred. Device has not been updated.',
  GROUP_CREATE_SUCCESS: 'Group created successfully.',
  GROUP_CREATE_ERROR: 'An error occured, please try again.',
  GROUP_UPDATE_SUCCESS: 'Group updated successfully.',
  GROUP_UPDATE_ERROR: 'An error occured, please try again.',
  GROUP_DELETE_SUCCESS: 'Group removed successfully.',
  GROUP_DELETE_ERROR: 'An error occured, please try again.',
  ORG_UPDATE_SUCCESS: 'Venue updated successfully.',
  ORG_UPDATE_ERROR: 'An error occured, please try again.',
  REPORT_SAVE_XLSX_ERROR: 'Could not save xlsx, please try again.',
  REPORT_SAVE_PDF_ERROR: 'Could not save pdf, please try again.',
  REPORT_PRINT_ERROR: 'Could not print report, please try again.',
  REPORT_LOAD_ERROR: 'Could not load report, please try again.',
  PAYMENT_STRIPE_ERROR: 'We had problem contacting the payment processor.Please try again in a moment.',
  PAYMENT_SAVE_SUCCESS_TITLE: 'Your Plan Was Updated!',
  PAYMENT_SAVE_SUCCESS_MESSAGE: 'The license will be updated within a couple of minutes.',
  FILE_TOO_LARGE:'This file exceeds maximum allowed size of 350 kB',
  FILE_UPLOAD_ERROR: 'An error occured, please try again.',
  BILLING_DATA_UPDATE_SUCCESS: 'Billing information updated successfully.',
  BILLING_DATA_UPDATE_ERROR: 'An error occured, please try again.',
  IMPORT_MEMBERS_PROCESSING_ERROR: 'Processing failed. Please make sure that file format is correct and try again.',
  IMPORT_MEMBERS_VALIDATION_ERROR: `Provided data has validation issues. You can correct the file, click 'Back' configure it again.\n
    If you decide to import without fixing the data, rows with missing or invalid phone numbers (errors)
    will be skipped and incorrect name or date values (warnings) will be left blank.`,
  PAYMENT_METHOD_SAVE_SUCCESS_MESSAGE: 'Your payment method has been updated.',
  PASSWORD_SET_SUCCESS: 'Your password has been set. Please log in.',
  PASSWORD_SET_LINK_INVALID: 'The provided password set link is invalid or has expired. Please request a new one.',
  REPORT_FAILED_TO_GENERATE : 'Failed to generate report.',
  QR_CODE_COPIED: 'QR code copied to clipboard.',
};
