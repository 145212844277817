import { Fetched, createFetched } from '../../api/common/fetched';
import { Org } from '../org/models/org';

export interface CheckInState {
  getOrgOperation: Fetched<Pick<Org, 'id' | 'image' | 'name' | 'transparentImageBackground'>, string>;
  checkInOperation: Fetched<void, string>;
}

export const initialState: CheckInState = {
  getOrgOperation: createFetched(),
  checkInOperation: createFetched(),
};
